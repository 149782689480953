<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab dark color="primary" v-bind="attrs" v-on="on"><v-icon dark> mdi-calendar </v-icon></v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    ##&en Calendar date ##&hu Naptári nap ##
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false"><v-icon> mdi-close </v-icon></v-btn>
                </v-card-title>

                <v-date-picker v-model="picker"></v-date-picker>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab dark color="primary" @click="updateDate()"><v-icon dark> mdi-calendar-cursor </v-icon></v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>
<script>
// we use teh v-model to set a date, and we notify  with an event of the changes
// <datepicker-dialog v-model="datestamp" v-on:update:date="setDate()" />
export default {
    name: "app-date-selector",
    props: ["value"],
    data() {
        return {
            dialog: false,
            picker: new Date().toISOString().substr(0, 10),
        };
    },
    methods: {
        updateDate() {
            const date = new Date(this.picker);
            date.setHours(0);
            this.$emit("input", date);
            this.$emit("update:date", this.picker);
            this.dialog = false;
        },
        /*
        incDisabled() {
            let next = new Date();
            next.setDate(next.getDate() - 1);
            return next.getTime() < new Date(this.picker).getTime();
        },*/
    },
};
</script>
