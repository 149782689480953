<template>
    <v-app id="app" data-app>
        <div id="offline_overlay"  v-if="is_user() && !$socket.connected">
            <v-snackbar v-model="offline" timeout="-1" color="red" multi-line min-width="500" min-height="500" top>
                OFFLINE
            </v-snackbar>
        </div>
        <login_dialog ref="login_dialog" />
        <div v-if="!is_user()" id="login_layout_frame" class="center"><login_layout /></div>
        <div v-else>
            <app_navigation v-if="is_user()" />
            <app_main v-if="is_user()" />
        </div>
    </v-app>
</template>

<script>
//

import app_navigation from "@/navigation.vue";
import app_main from "@/main.vue";
import login_layout from "@/login-components/LoginLayout.vue";
import login_dialog from "@/login-components/LoginDialog.vue";

//import me

export default {
    name: "app",

    data: function () {
        return {
        	offline: true
        };
    },
    components: {
        app_navigation,
        app_main,
        login_layout,
        login_dialog,
    },
    methods: {
        is_user() {
            if (!this.$store) return false;

            if (!this.$store.state) return false;
            if (!this.$store.state.server.session) return false;
            if (!this.$store.state.server.session.passport) return false;
            if (!this.$store.state.server.session.user) return false;
            if (!this.$store.state.server.session.user.name) return false;
            if (this.$store.state.server.session.user.name === this.$store.state.server.session.passport.user) return false;

            return true;
        },
    },
    mounted() {
        this.$root.login_dialog = this.$refs.login_dialog;
    },
    watch: {
        "$store.state.server.session.user.theme"(nv) {
            if (nv) this.$vuetify.theme.themes.light.primary = nv;
        },
    },
};
/*
 <div id="login_layout_frame">
                        ídxcoévíxscv
                        <login_layout /></div
                >
*/
</script>

<style scoped>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

#login_layout_frame {
    width: 400px;
    max-width: 100%;
    border-radius: 4px;
    background-color: powderblue;
    margin-top: 0;
}
#login_layout_frame > div {
    overflow: hidden;
    border-radius: 5px;
}
.center {
    margin: auto;
    width: 50%;
}
#offline_overlay {
    z-index: 100000;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
