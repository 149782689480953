import Vue from "vue";

// initial state
const state = {};

// mutations
const mutations = {
    SOCKET_DIAGRAMLIST(state, payload) {
        for (let s in payload) {
            // state[s] = payload[s];
            if (!state[s]) Vue.set(state, s, payload[s]);
            else for (let x in payload[s]) Vue.set(state[s], x, payload[s][x]);
        }
    },
    SOCKET_DIAGRAMDATA(state, payload) {
        for (let s in payload) {
            // state[s] = payload[s];
            if (!state[s]) Vue.set(state, s, payload[s]);
            else for (let x in payload[s]) Vue.set(state[s], x, payload[s][x]);
        }
    },
};

const getters = {
    getDiagramData: (state, getters) => (id) => {
        if (!id) return null;
        if (!state[id]) return null;
        if (!state[id].data) return null;
        return state[id].data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
