<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title"> Settings </v-card-title>

        <qrcode-vue :value="getCode()" :size="size" level="H" />
        <pre>{{ getCode() }}</pre>
        <v-btn class="ma-2" outlined href="/debug/app-debug.apk" target="_blank">##&en Download Android Debug APK ##&hu Android Debug APK letöltése ##</v-btn>
        <v-container fluid>
            <v-checkbox v-for="(v, k, i) in checkboxes" v-model="checkboxes[k]" :label="k" dense></v-checkbox>
        </v-container>
    </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: "f0x_settings",
    data() {
        return {
            size: 300,
            checkboxes: {
                use_camera_photo: true,
                //use_camera_barcode: true,
                //use_settings: true,
                use_nfc: false,
                use_ble: false,
                use_logs: false,
            },
        };
    },
    methods: {
        getCode() {
            let code = "$F0X://" + "HOSTNAME=" + ß.HOSTNAME + "|PASSPORT=" + this.$store.state.server.session.passport.user;
            let _this = this;
            Object.keys(_this.checkboxes).forEach((k) => {
                if (_this.checkboxes[k] === false) code += "|" + k.toUpperCase() + "=false";
                if (_this.checkboxes[k] === true) code += "|" + k.toUpperCase() + "=true";
                    
            });
            return code;
        },
    },
    components: {
        QrcodeVue,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
