<template>
    <section class="relative dark-bg">
        <v-container fill-height dark>
            <v-layout>
                <v-row justify="center" no-gutters>
                    <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="route in homepage_routes()" :key="route.name">
                        <v-card hover class="pa-2" style="max-width: 360px; width: 360px; margin: auto" color="primary" @click.native="open(route)">
                            <div class="pa-5 img-cont">
                                <v-responsive :aspect-ratio="10 / 1"
                                    ><v-icon>{{ route.icon }}</v-icon></v-responsive
                                >
                            </div>
                            <v-card-title class="text-center text-uppercase" style="display: block">{{ route.name }}</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-layout>
        </v-container>
    </section>
</template>
<script>
import DatabaseCard from "@/database-components/database-card.vue";

export default {
    name: "home",
    route: { path: "/", icon: "mdi-home", toolbar: true, sidebar: true },
    data() {
        return {};
    },
    created() {},
    methods: {
        onClick(to) {
            this.$router.push("/" + to);
        },
        open(route) {
            if (route.open) return this.$router.push(route.open);
            this.$router.push(route.path.split(":")[0].split("*")[0]);
        },
        homepage_routes() {
            const session = this.$store.state.server.session;
            if (!session.usecases) return [];
            const routes = this.$router.options.routes.filter(function (route) {
                if (!route.homepage) return false;
                if (route.usecase) if (session.usecases[route.usecase] === true) return true;
                return false;
            });
            return routes.sort((a, b) => a.path.localeCompare(b.path));
        },
    },
    watch: {
        $route(to, from) {},
    },
};
</script>
