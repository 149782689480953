<template>
    <v-card :key="key">
        <v-card-actions class="pa-5">
            <datepicker-dialog v-model="datestamp" v-on:update:date="setDate()" />
            <v-btn class="mx-2" fab dark color="primary" @click="decDate()">
                <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark color="primary" @click="incDate()" :disabled="incDisabled()">
                <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
            <h1 class="pa-5 text-uppercase">{{ getTitle() }}</h1>
        </v-card-actions>
        <v-data-table
            :headers="headers"
            :items="eventstats"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :search="search"
            item-key="name"
            :footer-props="footer_props"
            show-expand
            :key="singleExpand"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <!--v-switch v-model="singleExpand" label="##&en Single expand ##&hu Egy látható ##" class="mt-6"></v-switch-->
                    <v-btn @click="expandAll" elevation="2" class="ml-5" color="primary" fab x-small>  <v-icon dark> mdi-chevron-down </v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td v-if="item.comment" :colspan="headers.length" class="pa-5">
                    <pre>{{ item.comment }}</pre>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
// milyen gép, milyen státuszban, mennyi ideig volt milyen időszakban?
import DatepickerDialog from "@/components/datepicker-dialog.vue";
const lang_locale = "##&en en-GB ##&hu hu-HU ##";

export default {
    name: "eventstats",
    route: { path: "/eventstats/:datestamp?", icon: "mdi-calendar-text", toolbar: false, sidebar: true, homepage: true, usecase: "eventstats" },
    data() {
        let datestamp = new Date();
        if (this.$route.params.datestamp) datestamp = new Date(this.$route.params.datestamp);
        else this.$router.push("/eventstats/" + ß.getFormattedDate(this.datestamp));

        return {
            footer_props: {
                "items-per-page-options": [20, 100, 200, -1],
            },
            singleExpand: true,
            expanded: [],
            search: "",
            headers: ß.EVENTSTATS.table_headers,
            eventstats: [],
            datestamp: datestamp,
            key: 0,
        };
    },
    components: { DatepickerDialog },
    created() {
        this.update();
    },
    methods: {
        expandAll() {
            this.expanded = [];
            this.eventstats.forEach((e) => this.expanded.push(e));
        },
        update() {
            let _this = this;
            this.eventstats = [];

            // first, grab data from the servers datamemory
            this.$socket.client.emit("get_eventstats", this.datestamp.getTime(), function (eventstats) {
                _this.eventstats = eventstats;
            });
        },
        incDisabled() {
            let next = new Date();
            next.setDate(next.getDate() - 1);
            return next.getTime() < this.datestamp.getTime();
        },
        incDate() {
            this.datestamp.setDate(this.datestamp.getDate() + 1);
            this.$router.push("/eventstats/" + ß.getFormattedDate(this.datestamp));

            this.update();
        },
        decDate() {
            this.datestamp.setDate(this.datestamp.getDate() - 1);
            this.$router.push("/eventstats/" + ß.getFormattedDate(this.datestamp));

            this.update();
        },
        setDate() {
            this.$router.push("/eventstats/" + ß.getFormattedDate(this.datestamp));
            this.update();
        },
        getColor(name) {
            if (!name) return "yellow";
            let event = name.split(" ")[0].toLowerCase();
            if (event === "error") return "red";
            if (event === "warning") return "orange";
            else return "green";
        },
        getTitle() {
            return this.datestamp.toLocaleDateString(lang_locale) + " " + this.datestamp.toLocaleDateString(lang_locale, { weekday: "long" });
        },
        getInfo() {
            Ł("GET INFO");
        },
        getTime(at) {
            const date = new Date(at);
            const hours = "0" + date.getHours();
            const minutes = "0" + date.getMinutes();
            const seconds = "0" + date.getSeconds();
            return hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
        },
    },
    watch: {
        $route(to, from) {},
    },
};
</script>

<style scoped></style>
