// @DOC Create a date object with concrete values

export default function date(date) {
    if (!date) date = new Date();
	if (date instanceof Date === false) date = new Date(date);
        
     const o = {};

        o.getFullYear = date.getFullYear();
    	o.getMonth = date.getMonth();
        o.getDate = date.getDate();
        o.getDay = date.getDay();
        o.getHours = date.getHours();
        o.getMinutes = date.getMinutes();
        o.getSeconds = date.getSeconds();
        o.getTime = date.getTime();
        
        o.timestamp = Math.floor(date.getTime() / 1000);

    return o;
}