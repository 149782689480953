//import Vue from "vue";

// initial state
const state = {
    cgtop: "",
};

// mutations
const mutations = {
    SOCKET_CGTOP(state, payload) {
        state.cgtop = '\n' + payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
