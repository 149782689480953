<template>
    <div v-resize="onResize">
        <div id="icicle-content">
            <div id="icicle-d3">##&en Loading ... ##&hu Betöltés ... ##</div>
        </div>
        <v-bottom-navigation fixed background-color="primary">
            <v-btn class="mx-2" fab dark color="primary" @click="refresh()"><v-icon dark> mdi-refresh </v-icon></v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
import define from "@/diagram-components/icicle.d3.js";

export default {
    name: "diagram-icicle",
    props: ["id"],
    data: function () {
        return {
            icicle_d3: null,
            width: window.innerWidth,
            height: window.innerHeight,
        };
    },
    mounted() {
        // wait for server side run functions to complete
        setTimeout(() => {
            this.icicle_d3 = define(this);
        }, 1000);
    },
    methods: {
        refresh() {
        	this.icicle_d3 = define(this);
        },
        onResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            //Ł(this.databaseschema_d3);
            if (this.icicle_d3) this.icicle_d3.setViewBox();
        },
    },
};
</script>
<style scoped>
.display-text {
}
.display-container {
    cursor: pointer;
}
</style>
