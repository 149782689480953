const Ł = console.log;

const SPACE = 50;
const WIDTH = 400;
const HEIGHT = 40;

export default function draw_connections(that, schema) {
    /*
    const connections = [];
    for (const db in schema)
        for (const field in schema[db])
            if (schema[db][field].ref)
                connections.push({
                    db: db,
                    field: field,
                    ref: schema[db][field].ref,
                    multiple: schema[db][field].multiple || false,
                });

    // Ł(connections);

    const element = d3.select("#databaseschema-connections");
    element.selectAll("line").remove();
    const lines = element.selectAll("line").data(connections).enter();

    const l = 10;

    lines
        .append("line")
        .attr("id", (d) => d.db + "." + d.field + "-" + d.ref)
        .style("stroke", (d) => {
            if (d.multiple) return "rgba(200,0,0,0.4)";
            return "rgba(0,0,200,0.4)";
        })
        .attr("stroke-width", 3)
        .attr("x1", function (d, i) {
            return Number(WIDTH) + Number(d3.select("#" + d.db).attr("x"));
        })
        .attr("y1", function (d, i) {
            const offset = Number(d3.select("#" + d.db + "-" + d.field).attr("y"));
            return Number(HEIGHT / 2) + Number(d3.select("#" + d.db).attr("y")) + offset;
        })
        .attr("x2", function (d, i) {
            return Number(d3.select("#" + d.ref).attr("x"));
        })
        .attr("y2", function (d, i) {
            return Number(HEIGHT / 2) + Number(d3.select("#" + d.ref).attr("y"));
        })
        .attr("d", function (d, i) {
            return d.db + "-" + d.field + "--" + d.ref;
        });
        */
}
