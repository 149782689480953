import { render, staticRenderFns } from "./databaseschema.vue?vue&type=template&id=8aee9254&"
import script from "./databaseschema.vue?vue&type=script&lang=js&"
export * from "./databaseschema.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../@vue-modules/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../@vue-modules/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})


/* vuetify-loader */
import installDirectives from "!../../../../@vue-modules/vuetify/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
