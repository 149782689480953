<template>
    <div v-resize="onResize">
        <div id="databaseschema-d3"></div>
        <v-bottom-navigation fixed background-color="primary">
            <v-btn class="mx-2" fab dark color="primary" @click="open()" disabled><v-icon dark> mdi-pencil </v-icon></v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
import define from "@/databaseschema.d3.js";

export default {
    name: "databaseschema",
    route: { path: "/databaseschema", icon: "mdi-code-json", toolbar: false, sidebar: true, homepage: false, usecase: "databaseschema" },
    components: {},
    data: function () {
        return {
            databaseschema_d3: null,
            width: window.innerWidth,
            height: window.innerHeight,
        };
    },
    created() {
        //this.generateReport();
    },
    mounted() {
        this.databaseschema_d3 = define(this);
    },
    methods: {
        open() {},
        onResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            //Ł(this.databaseschema_d3);
            if (this.databaseschema_d3) this.databaseschema_d3.setViewBox();
        },
        /* generateReport() {
            let payload = "Para";
            this.$socket.client.emit("generate_report", payload, (data) => {
                this.report = data;
            });
        },*/
    },
};
</script>
