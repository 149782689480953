<template>
    <v-card class="mx-auto">
		<h3>systemd-cgtop</h3>
		<pre>
    		{{ $store.state.systemd.cgtop || 'no-data'}}
    	</pre>

    </v-card>
</template>
<script>    
    

export default {
    name: "system", 
    route: { path: "/systemd", icon: "mdi-cog", toolbar: false, sidebar: true, homepage: false, usecase: "debug-logs" },
    data() {
        return {
        };
    },
    methods: {
        onResize() {
            if (window.innerHeight > 400) this.height = Math.floor(window.innerHeight - 400);
        },

    },
};
</script>

<style scoped>
.job-card {
    padding: 20px;
    max-width: 800px;
    min-width: 400px;
}
</style>