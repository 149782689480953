<template>
    <v-container fill-height dark>
        <v-layout>
            <v-row justify="center" no-gutters>
                <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="(o, id, index) in $store.state.diagramdata" :key="index">
                    <v-card class="pa-2" style="max-width: 360px; width: 360px; margin: auto" @click.native="show(id)">
                        <div class="pa-5 img-cont">
                            <v-btn class="mx-2 ma-3" fab color="primary"><v-icon>{{o.icon}}</v-icon></v-btn>
                        </div>
                        <v-card-title class="text-center text-uppercase" style="display: block">{{ o.name }}</v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "diagrams",
    route: { path: "/diagrams", icon: "mdi-chart-pie", toolbar: true, sidebar: true, homepage: true, usecase: "diagrams" },
    methods: {
        show(id) {
            this.$socket.client.emit("update-diagram", id, () => {
                this.$router.push("/diagram/" + id);
            });
        },
    },
};
</script>

<style scoped></style>
