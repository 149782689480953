<template>
    <v-row id="calendar-row">
        <v-col>
            <v-sheet height="64">
                <v-toolbar flat>
                    <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> ##&en Today ##&hu Mai Nap ## </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on">
                                <v-icon right> mdi-plus </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="db in databases" @click="addDocument(db)">
                                <v-list-item-title>{{ db }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>##&en Day ##&hu Nap ##</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>##&en Week ##&hu Hét ##</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>##&en Month ##&hu Hónap ##</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>##&en 4 Days ##&hu 4 Nap ##</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet id="calendar-sheet">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                ></v-calendar>
                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                    <v-card color="grey lighten-4" min-width="350px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                            <v-btn icon @click="openDocumentDialog(selectedEvent)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-title v-html="selectedEvent.db"></v-toolbar-title>

                            <v-icon>mdi-database</v-icon>
                        </v-toolbar>
                        <v-card-text>
                            <span v-html="getHtmlDetails(selectedEvent)"></span>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false"> OK </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
        </v-col>
        <database-document-dialog :db="db" :document="document" v-model="dialog" :key="key" />
    </v-row>
</template>

<script>
function stringToColour(str) {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (var ii = 0; ii < 3; ii++) {
        const value = (hash >> (ii * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
}

import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";

export default {
    name: "calendar",
    components: { DatabaseDocumentDialog },
    route: { path: "/calendar", icon: "mdi-calendar", toolbar: true, sidebar: true, homepage: true, usecase: "calendar", open: "/calendar" },
    data: () => ({
        focus: "",
        type: "month",
        typeToLabel: {
            month: "##&en Month ##&hu Hónap ##",
            week: "##&en Week ##&hu Hét ##",
            day: "##&en Day ##&hu Nap ##",
            "4day": "##&en 4 Day ##&hu 4 Nap ##",
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
        names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
        databases: [],
        db: null,
        document: null,
        dialog: null,
        key: null,
    }),
    mounted() {
        this.$refs.calendar.checkChange();
    },
    methods: {
        addDocument(db) {
            this.db = db;
            this.document = null;
            this.dialog = true;
            this.key = new Date().getTime();
        },
        openDocumentDialog(event) {
            this.db = event.db;
            this.document = event.document;
            this.dialog = true;
            this.key = event.document._id;
        },
        getHtmlDetails(event) {
            let html = "<pre>";
            html += "DB: " + event.db;
            //html += "ID: " + event.document._id;

            html += "</pre>";
            return html;
        },
        viewDay({ date }) {
            this.focus = date;
            this.type = "day";
        },
        getEventColor(event) {
            return event.color;
        },
        setToday() {
            this.focus = "";
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        showEvent({ nativeEvent, event }) {
            this.openDocumentDialog(event);

            /*
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation();
            */
        },
        updateRange({ start, end }) {
            const events = [];

            const databases = this.$store.getters["database/getDatabaseList"].filter((db) => {
                const datafields = this.$store.getters["database/getDatabaseDatafields"](db);
                return datafields.includes("date") && datafields.includes("duration") && datafields.includes("name");
            });

            this.databases = databases;

            databases.forEach((db) => {
                const color = stringToColour(db);
                const documents = Object.values(this.$store.state.database[db].data);
                /*.filter(
                    (e) => new Date(`${start.date}T00:00:00`) < e.date && e.date < new Date(`${end.date}T23:59:59`)
                );*/
                //Ł(documents);
                documents.forEach((document) => {
                    const start = new Date(document.date);
                    const end = new Date(start.getTime() + document.duration * 1000);

                    events.push({
                        name: document.name,
                        start,
                        end,
                        color: document.color || color,
                        timed: true,
                        db,
                        document,
                    });
                });
            });
            /*
            const min = new Date(`${start.date}T00:00:00`);
            const max = new Date(`${end.date}T23:59:59`);
            const days = (max.getTime() - min.getTime()) / 86400000;
            const eventCount = this.rnd(days, days + 20);

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0;
                const firstTimestamp = this.rnd(min.getTime(), max.getTime());
                const first = new Date(firstTimestamp - (firstTimestamp % 900000));
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
                const second = new Date(first.getTime() + secondTimestamp);

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                });
            }
*/
            //Ł(databases, start, end, events);

            this.events = events;
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },
    },
    watch: {
        "$store.state.database"(to, from) {
            Ł("@calendar watch");
            this.updateRange({
                start: new Date(),
                end: new Date(),
            });
        },
        dialog(to, from) {
            Ł("dialog");
            this.updateRange({
                start: new Date(),
                end: new Date(),
            });
        },
    },
};
</script>
<style scoped>
#calendar-row {
    height: calc(99vh - 64px);
}
#calendar-sheet {
    height: calc(99vh - 128px);
}
</style>
