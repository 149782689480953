<template>
    <timeline db="jobs" sortimenter="machines" property="machine" ref="timeline" :methods="{ getColor }" />
</template>

<script>
import timeline from "@/timeline-components/timeline.vue";

export default {
    name: "jobs-timeline",
    route: { path: "/jobs", icon: "mdi-chart-timeline", toolbar: true, sidebar: true, homepage: true, usecase: "jobs", open: "/jobs" },
    components: { timeline },
    data() {
        return {};
    },
    mounted() {
        console.log("timeline update @ mounted");
        this.$refs.timeline.update();
    },
    methods: {
        getColor(e) {
            let color = this.$vuetify.theme.themes.light.primary;
            if (e.jobstatus !== undefined) {
                let statusarray = this.$store.getters["database/getDatabaseArrayData"]("jobstatus").filter((j) => e.jobstatus === j._id);
                if (statusarray.length > 0) color = statusarray[0].color;
            }
            return color + "99";
        },
    },

    sockets: {
        // this is hacking neceserry to deal with the virtualdom bugs
        database_update(val) {
            const db = val.db;
            if (db !== "jobs" && db !== "machines") return;
            console.log(val);
            setTimeout(this.$refs.timeline.update,200);
        },
        database_document(val) {
            const db = val.db;
            if (db !== "jobs" && db !== "machines") return;
            setTimeout(this.$refs.timeline.update,200);
        },
    },

    watch: {
        "$store.state.database.machines": function (newVal, oldVal) {
            console.log("timeline update @ watch machines");
            this.$refs.timeline.update();
        },
        // does not work properly.
        "$store.state.database.jobs": function (newVal, oldVal) {
            console.log("timeline update @ watch jobs");
            this.$refs.timeline.update();
        },
    },
};
</script>
<style scoped></style>
