// initial state
const state = {
	que: {}
};

// mutations
const mutations = {
    SOCKET_REGULATIONS_UPDATE(state, payload) {
        state.que = payload.que;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
};