<template>
    <v-main id="main">
        <transition name="component-fade" mode="out-in">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </transition>
    </v-main>
</template>

<script>
//import load_components from "@/load_components.js";
//import list_components from "@/list_components.js";

//import dash from "@/machine.vue";

export default {
    name: "mainpage",
    props: {},
    data: function () {
        return {};
    },
    components: {
        //dash,
    },
    mounted: function () {
        //Ł(this.$router.options.routes);
    },
    methods: {},
};
</script>
<style scoped>
#main {
    padding: 64px;
    border: 0px;
    margin: 0px;
}
</style>
