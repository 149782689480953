<template>
    <v-card :key="loaded">
        <v-card-actions class="pa-5">
            <datepicker-dialog v-model="datestamp" v-on:update:date="setDate()" />
            <v-btn class="mx-2" fab dark color="primary" @click="decDate()">
                <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark color="primary" @click="incDate()" :disabled="incDisabled()">
                <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
            <h1 class="pa-5 text-uppercase">{{ getTitle() }}</h1>
        </v-card-actions>
        <v-toolbar flat>
            <v-btn
                v-for="db in Object.keys(eventlog)"
                @click="
                    showing = db;
                    key++;
                "
                elevation="2"
                class="ml-5"
                color="primary"
            >
                {{ db.split("_")[0] }}</v-btn
            >
        </v-toolbar>
        <v-data-table :key="key" :headers="headers" :items="eventlog[showing]" :search="search" item-key="info" :footer-props="footer_props" show-expand>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-5">
                    <pre> {{ item.info }} </pre>
                </td>
            </template>
            <template v-slot:item.name="{ item }">
                <v-icon :color="getColor(item.name)"> mdi-information </v-icon>
                {{ item.name }}
            </template>
            <template v-slot:item.timestamp="{ item }">
                {{ getTime(item.createdAt) }}
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import DatepickerDialog from "@/components/datepicker-dialog.vue";
const lang_locale = "##&en en-GB ##&hu hu-HU ##";
export default {
    name: "eventlog",
    route: { path: "/eventlog/:datestamp?", icon: "mdi-calendar-text-outline", toolbar: true, sidebar: true, homepage: true, usecase: "eventlog", open: "/eventlog" },
    data() {
        let datestamp = new Date();
        if (this.$route.params.datestamp) datestamp = new Date(this.$route.params.datestamp);
        else this.$router.push("/eventlog/" + ß.getFormattedDate(this.datestamp));

        return {
            footer_props: {
                "items-per-page-options": [20, 100, 200, -1],
            },
            search: "",
            headers: ß.EVENTLOG.table_headers,
            eventlog: {}, // key: array
            datestamp: datestamp,
            key: 0,
            loaded: 0,
            showing: "eventlog",
        };
    },
    components: { DatepickerDialog },
    created() {
        this.update();
    },
    methods: {
        expandAll() {
            this.expanded = [];
            this.eventlog.forEach((e) => this.expanded.push(e));
        },
        format(e) {
            let _this = this;
            let elx = {};
            elx.name = e.name;
            elx.info = "";
            elx.createdAt = e.createdAt;

            // create details based on all props.
            Object.keys(e).forEach((key) => {
                let value = e[key];
                let ref = null;
                if (_this.$store.state.database.eventlog.format[key]) ref = _this.$store.state.database.eventlog.format[key].ref;
                if (ref)
                    if (_this.$store.state.database[ref]) if (_this.$store.state.database[ref].data[e[key]]) value += " = " + _this.$store.state.database[ref].data[e[key]].name;

                elx.info += key + ": " + value + "\n";
                //elx[key] = value;
            });

            return elx;
        },
        update() {
            this.eventlog = {};

            // first, grab data from the servers datamemory
            this.$socket.client.emit("get_eventlog", this.datestamp.getTime(), (payload) => {
                //Ł(payload);
                for (const db in payload) this.eventlog[db] = payload[db].map((e) => this.format(e));
                this.key++;
                //Ł(this.eventlog);
            });
        },
        incDisabled() {
            let next = new Date();
            next.setDate(next.getDate() - 1);
            return next.getTime() < this.datestamp.getTime();
        },
        incDate() {
            this.datestamp.setDate(this.datestamp.getDate() + 1);
            this.$router.push("/eventlog/" + ß.getFormattedDate(this.datestamp));

            this.update();
        },
        decDate() {
            this.datestamp.setDate(this.datestamp.getDate() - 1);
            this.$router.push("/eventlog/" + ß.getFormattedDate(this.datestamp));

            this.update();
        },
        setDate() {
            this.$router.push("/eventlog/" + ß.getFormattedDate(this.datestamp));
            this.update();
        },
        getColor(name) {
            if (!name) return "yellow";
            let event = name.split(" ")[0].toLowerCase();
            if (event === "error") return "red";
            if (event === "warning") return "orange";
            else return "green";
        },
        getTitle() {
            return this.datestamp.toLocaleDateString(lang_locale) + " " + this.datestamp.toLocaleDateString(lang_locale, { weekday: "long" });
        },
        getInfo() {
            Ł("GET INFO");
        },
        getTime(at) {
            const date = new Date(at);
            const hours = "0" + date.getHours();
            const minutes = "0" + date.getMinutes();
            const seconds = "0" + date.getSeconds();
            return hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
        },
    },
    watch: {
        $route(to, from) {},
    },
    sockets: {
        update_eventlog(o) {

            if (new Date().getDate() === this.datestamp.getDate())
                for (const db in o) {
                    if (!this.eventlog[db]) this.eventlog[db] = [];
                    this.eventlog[db].push(this.format(o[db]));
                }
        },
    },
};
</script>
