const Ł = console.log;

import databaseschema_drawer from "./databaseschema-drawer.d3.js";
import databaseschema_connections from "./databaseschema-connections.d3.js";

function setViewBox() {
    d3.select("#databaseschema-viewbox").attr("viewBox", "0 0 " + window.innerWidth + " " + (window.innerHeight - 128));
}

export default function define(that) {
    if (!that) that = {};

    d3.select("#databaseschema-d3").style("background-color", "lightgray");

    //that.$socket.client.on("database_update_one", (data) => Ł("d3database update one", data));
    //that.$socket.client.on("database_update_all", (data) => Ł("d3database update all", data));

    const svg = d3
        .select("#databaseschema-d3")
        .classed("svg-container", true)
        .append("svg")
        .attr("id", "databaseschema-viewbox")
        //.attr("viewBox", "0 0 " + that.width || width + " " + ((that.height || height) - 160))
        //.attr("preserveAspectRatio", "xMinYMin meet")
        .classed("svg-content-responsive", true)
        .style("cursor", "default");

    setViewBox();

    // the background is the zooming handle surface

    const zoombackground = svg.append("rect").attr("id", "zoombackground").attr("x", 0).attr("y", 0).attr("width", 20000).attr("height", 10000).attr("fill", "#eee");
    const zoomlayer = svg.append("g").attr("id", "zoomlayer");

    function zoomed({ transform }) {
        zoomlayer.attr("transform", transform);
    }
    const zoom = d3.zoom().on("zoom", zoomed).scaleExtent([0.1, 20]);
    zoombackground.call(zoom);

    const main = zoomlayer.append("svg").attr("id", "databaseschema-main").attr("x", 10).attr("y", 10).attr("width", 20000).attr("height", 10000);
    const connections = zoomlayer.append("svg").attr("id", "databaseschema-connections").attr("x", 10).attr("y", 10).attr("width", 20000).attr("height", 10000);

    // using vue and socket.io
    //that.$socket.client.on("d3databaseschema", (schema) => {

    if (that.$socket) that.$socket.client.emit("d3databaseschema", render);
    else {
        // grab data without vue eventually
        //const schema = await d3.json("/databaseschema.json");
        render(schema);
    }

    function render(schema) {
        databaseschema_drawer(that, schema);
        databaseschema_connections(that, schema);
    }

    return {
        render,
        setViewBox,
    };
}
