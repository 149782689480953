<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-card v-for="(q, key, i) in $store.state.regulations.que" elevation="4" :key="i" width="1000" class="ma-2">
                <v-card-text class="text-left details pt-3">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>##&en Database ##&hu Adatbázis ##</td>
                                    <td>{{ q.rule.database.toUpperCase() }} {{ q.rule.datafield }} [{{ q.document._id }}]</td>
                                </tr>
                                <tr>
                                    <td>##&en Document ##&hu Dokumentum ##</td>
                                    <td>
                                        <b>{{ q.document.name }}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>##&en Regulation ##&hu Szabályzás ##</td>
                                    <td>
                                        {{ q.rule.name }} <i>{{ q.rule.comment }}</i>
                                    </td>
                                </tr>
                                <tr v-if="q.value !== undefined">
                                    <td>##&en Resolution ##&hu Kiértékelés ##</td>
                                    <td>
                                        <b>{{ q.value }}</b>
                                    </td>
                                </tr>
                                <tr v-if="q.error !== undefined">
                                    <td>##&en Error ##&hu Hiba ##</td>
                                    <td>{{ q.error }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider vertical></v-divider>
                <v-card-actions>
                    <v-btn class="mx-2" fab color="primary" @click="clear(q, key, i)"> <v-icon> mdi-trash-can-outline </v-icon> </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab color="primary" @click="open(q, key, i)"> <v-icon> mdi-database-edit-outline </v-icon> </v-btn>
                </v-card-actions>
            </v-card>
            <database-document-dialog v-if="db" :db="db" :document="document" v-model="dialog" :key="dialogKey" />
        </v-row>
    </v-container>
</template>
<script>
import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";

export default {
    name: "regulations",
    components: { DatabaseDocumentDialog },
    route: { path: "/regulations", icon: "mdi-check-circle", toolbar: true, sidebar: true, homepage: true, usecase: "regulations" },
    data() {
        return {
            que: {},
            dialog: false,
            dialogKey: 10000,
            db: null,
            document: null,
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        update() {
            // first, grab data from the servers datamemory
            this.$socket.client.emit("get_regulations", (que) => {});
        },
        open(q, k, i) {
            this.db = q.rule.database;
            this.document = deepCopy(q.document);

            this.document[q.rule.datafield] = q.value;
            this.dialogKey++;
            this.dialog = true;
        },
        clear(q, k, i) {
            console.log(q, k, i);
             this.$socket.client.emit("clear_regulation_entry", k);
        },
    },
};
// TODO implement this globally
function deepCopy(obj) {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    if (typeof obj !== "object") return obj;

    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        var copy = [];
        for (let i = 0; i < obj.length; i++) copy[i] = deepCopy(obj[i]);
        return copy;
    }
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);

        return copy;
    }
    console.log("Unable to copy", obj);
    return {};
}
</script>

<style scoped>
.details {
}
</style>
