<template>
    <v-container class="display-container" fill-height fluid @click="open()" v-bind:style="indication.style">
        <v-row justify="center">
            <div>
                <h1 class="display-text" style="font-size: 4rem">{{ indication.h1 }}</h1>
                <h2 class="display-text" style="font-size: 3rem">{{ indication.h2 }}</h2>
                <h3 class="display-text" style="font-size: 2rem">{{ indication.h3 }}</h3>
                <h4 class="display-text" style="font-size: 1rem">{{ indication.h4 }}</h4>
            </div>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "controlpanel-headline",
    props: ["indication"],
    data() {
        return {};
    },
    methods: {
    	open() {
            // 
            const uri = this.indication.uri;
        	if (uri) if (this.$route.path !== uri) this.$router.push(uri);
        }
    }
};
</script>
<style scoped>
    .display-text {
    }
    .display-container {
    	cursor: pointer;
    }
</style>