import axios from "axios";

// initial state
function newSession() {
    return {
        randomid: Math.random().toString(36).substr(2, 9).toUpperCase(),
        actions: {},
        navigation: "Actions",
        ble_items: [],
        barcode_items: [],
        photo_items: [],
        nfc_items: [],
    };
}

const state = newSession();

const mutations = {
    setName(state, { item, name }) {
        item.name = name;
    },
    setActions(state, actions) {
        state.actions = actions;
    },
    setNavigation(state, navigation) {
        state.navigation = navigation;
    },
    toggleSelection(state, item) {
        item.selected = !item.selected;
    },
    addBarcodeItem(state, item) {
        state.barcode_items.unshift(item);
    },
    addBleItem(state, item) {
        state.ble_items.unshift(item);
    },
    /*
    setBleItems(state, items) {
        state.ble_items = items;
    },
    addPhotoItem(state, item) {
        state.photo_items.push(item);
    },
    */
    addPhotos(state, items) {
        state.photo_items = items;
    },
    newSession(state, randomid) {
        state.randomid = randomid || Math.random().toString(36).substr(2, 9).toUpperCase();
        state.actions = {};
    },
    set(state, { key, value }) {
        state[key] = value;
    },
    SOCKET_SESSION_NFCS(state, response) {
        console.log("nfcs:", response);
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.nfc_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.nfc_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.nfc_items.push(other_item);
    },
    SOCKET_SESSION_BLES(state, response) {
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.ble_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.ble_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.ble_items.push(other_item);
    },
    SOCKET_SESSION_BARCODES(state, response) {
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.barcode_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.barcode_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.barcode_items.push(other_item);
    },
    SOCKET_SESSION_PHOTOS(state, response) {
        const known_items = Object.keys(response).map((e) => {
            return {
                name: response[e],
                key: e,
                selected: true,
            };
        });

        const keys = state.photo_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.photo_items.unshift(known_item);
    },
};

function send(payload) {
    if (!payload.message) payload.message = "unknown";
    const callback = payload.callback || function () {};

    axios({
        method: "post",
        url: "/post-f0x-update.json",
        headers: { "Content-Type": "application/json" },
        data: payload,
    })
        .then(function (response) {
            const data = response.data;

            console.log("[vuex/send] response:", data);
            if (callback) callback(data);
        })
        .catch((error) => {
            console.log("ERROR in session axios POST", error);
            //alert("Network error.");
        });
}

const actions = {
    newSession: ({ commit, state, rootGetters }, { randomid }) => {
        commit("newSession", randomid);

        const location = rootGetters["location/getLocation"];
        const payload = {
            message: "new_commsession",
            randomid: state.randomid,
            ...location,
        };
        //payload.username = rootGetters["settings/getUsername"];

        payload.callback = (o) => {
            console.log("NEW SESSION", o);
            if (o.actions) commit("setActions", o.actions);
            if (o.navigation) commit("setNavigation", o.navigation);
            if (o.commit) for (const i in o.commit) commit(i, o.commit[i]);
            if (o.dispatch) for (const i in o.commit) dispatch(i, o.dispatch[i]);
        };

        send(payload);
    },
    tap: ({ commit, state, dispatch, rootGetters }, { key, text, cb }) => {
        const payload = { ...rootGetters["location/getLocation"] };

        payload.randomid = state.randomid;

        payload.message = "action";
        payload.tap = key;
        payload.text = text;
        //payload.params = state.actions[key];

        payload.bles = state.ble_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.barcodes = state.barcode_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.photos = state.photo_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.nfcs = state.nfc_items.filter((e) => e.selected === true).map((e) => e.key);

        payload.callback = (o) => {
            console.log("ACTION post callback", o);
            if (o.actions) commit("setActions", o.actions);
            if (o.navigation) commit("setNavigation", o.navigation);
            if (o.commit) for (const i in o.commit) commit(i, o.commit[i]);
            if (o.dispatch) for (const i in o.commit) dispatch(i, o.dispatch[i]);

            if (o.alert) {
                const defaultOptions = {
                    title: "READY",
                    message: "",
                    okButtonText: "OK",
                    cancelable: false,
                };

                let params = { ...defaultOptions, ...o.alert };

                cb(params);
            }
        };
        console.log("send payload", payload);
        send(payload);
    },
    addBarcode({ commit, state, dispatch, rootGetters }, { text }) {
        const o = {};
        o.name = null;
        o.key = text;
        //o.barcode = result.text;
        o.format = "manual";
        o.selected = true;
        commit("addBarcodeItem", o);

        const payload = { message: "barcode", format: o.format, barcode: text };
        payload.randomid = state.randomid;

        payload.callback = (response) => {
            console.log("POST BLE result callback", response);
            state.barcode_items.forEach((e) => {
                if (response[e.key]) commit("setName", { item: e, name: response[e.key] });
            });
        };

        send(payload);
    },
    addBleItem({ commit, state, dispatch, rootGetters }, { text }) {
        const o = {};
        o.name = text;
        o.key = text;
        //o.barcode = result.text;
        //o.mac = text;
        o.selected = true;
        commit("addBleItem", o);

        const payload = { message: "BLE", bles: [text] };
        payload.randomid = state.randomid;

        payload.callback = (response) => {
            console.log("POST BLE result callback", response);
            state.ble_items.forEach((e) => {
                if (response[e.key]) commit("setName", { item: e, name: response[e.key] });
            });
        };

        send(payload);
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
