const Ł = console.log;


const SPACE = 50;
const WIDTH = 400;
const HEIGHT = 40;

export default function draw(that, schema){

    	const main = d3.select("#databaseschema-main");

    	// add default fields to the schema
        Object.keys(schema).forEach(e => {
            let o = {};
        	o.createdAt = { type: "Date"};
        	o.updatedAt = { type: "Date"};	
            schema[e] = { ...o, ...schema[e] }; 
        });
        
        // calculate maximum db height based on fields
        let maxfields = 1;
        for (const d in schema) if (Object.keys(schema[d]).length > maxfields) maxfields = Object.keys(schema[d]).length;

        const dbs = main.selectAll("rect").data(Object.keys(schema)).enter().append("svg");
        

        dbs.attr("id", (d) => d)
            .attr("class", "dbs")
            .attr("x", (d, i) => i * (WIDTH + SPACE));

        dbs.append("rect")
            .attr("fill", "#4caf50")
            .attr("width", WIDTH)
            .attr("height", (maxfields + 2) * HEIGHT); //(d) =>  (HEIGHT * (Object.keys(schema[d]).length)));

        /*
        // move whole db one step down?
        dbs.on("click", function (e, d) {
            console.log("rect click", e, d);
        	const clit = d3.select("#" + d);
            clit.attr("y", function (d) {
                return 600;
            });
        	clit.attr("clicked", true);
        	clit.select('rect').attr("fill", 'darkgreen');
        });*/

        dbs.append("text")
            //.attr("text-anchor", "middle")
            .attr("x", 20)
            .attr("y", 20)
            .text((d) => d.toUpperCase())
            .attr("class", "db-title");

        const fields = dbs
            .selectAll("g")
            .data((d) => Object.keys(schema[d]))
            .enter()
            .append("svg")
            .attr("id", function (d) {
                const db = d3.select(this.parentNode).datum();
                return db + "-" + d;
            })
            .attr("class", "db-fields")
            .attr("x", WIDTH / 10)
            .attr("y", (d, i) => HEIGHT * (i + 1))
            .attr("db-ref", function (d) {
                const db = d3.select(this.parentNode).datum();
                return schema[db][d].ref;
            })
            .attr("db-type", function (d) {
                const db = d3.select(this.parentNode).datum();
                return schema[db][d].type;
            });

        fields
            .append("rect")
            .attr("fill", function (d) {
                const db = d3.select(this.parentNode.parentNode).datum();
                const o = schema[db][d];
                //Ł(db, d, o);
                if (o.ref) return "greenyellow";
                if (o.type) {
                    if (o.type === "String") return "deepskyblue";
                    if (o.type === "Number") return "gold";
                    if (o.type === "Date") return "coral";
                    if (o.type === "Boolean") return "burlywood";
                    if (o.type === "Object") return "olivedrab";
                }
                return "white";
            })
            .attr("width", (9 * WIDTH) / 10)
            .attr("height", HEIGHT)
            .attr("stroke", "black")
            .attr("stroke-width", 3);

        fields
            .append("text")
            .attr("x", 25)
            .attr("y", 25)
            .text((d) => d.toUpperCase())
            .attr("class", "db-title");

        fields.on("click", function (e, d) {
            const db = d3.select(this.parentNode).datum();
            console.log(db, "field click", d);

            //that.$socket.client.emit("Ł", db + " field click " + d);

            const clit = d3.select("#" + db + "-" + d);
            let dir = 1;
            if (clit.attr("clicked")) dir = -1;
            const aty = Number(clit.attr("y"));
            clit.transition()
                .duration(900)
                .attr("y", function (d) {
                    return 600 * dir + aty;
                })
                .ease(d3.easePoly.exponent(3));
            clit.attr("clicked", !clit.attr("clicked"));
            //clit.select('rect').attr("fill", 'darkgreen');
        });


}