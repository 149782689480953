import Vue from "vue";

// initial state
const state = {
	sensorlist: [],
    detectorlist: []
};

// mutations
const mutations = {
    SOCKET_UPDATE_SENSORS(state, payload) {
        for (let s in payload) {
            //state[s] = payload[s];
            Vue.set(state, s, payload[s]);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
};
