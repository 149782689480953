<template>
    <div v-if="$store.state.diagramdata[id]">
        <icicle v-if="$store.state.diagramdata[id].type === 'icicle'" :id="id" />
        <sunburst v-if="$store.state.diagramdata[id].type === 'sunburst'" :id="id" />
    </div>
</template>
<script>
    
import icicle from "@/diagram-components/icicle.vue";
import sunburst from "@/diagram-components/sunburst.vue";

export default {
    name: "diagram",
    components: { icicle, sunburst },
    route: { path: "/diagram/:id?", icon: "mdi-chart-line" },
    data() {
        let id = "default-id";
        if (this.$route.params.id) id = this.$route.params.id;
        return {
            id,
        };
    },
    methods: {},
    watch: {
        "$route.params.id"(to, from) {
            if (this.$route.params.id !== this.id) {
                this.id = this.$route.params.id;
            }
        },
    },
};
</script>
