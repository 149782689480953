import Vue from "vue";

// initial state
const state = ß.INDICATORS;

// mutations
const mutations = {};

const getters = {
    getIndicatorComponent: (state, getters) => (key) => {
        if (!key) return {};
        const element = ß.INDICATORS[key];
        if (!element) return {};
        return element;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};